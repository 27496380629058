
import { initializeApp } from "../_snowpack/pkg/firebase/app.js";
import { getFirestore } from "../_snowpack/pkg/@firebase/firestore.js";
import { getAuth } from "../_snowpack/pkg/@firebase/auth.js";

const firebaseConfig = {
    apiKey: "AIzaSyAWee0rD6tY_LslKDTfRfLslkyGxfavL4w",
    authDomain: "ncsc-sim.firebaseapp.com",
    projectId: "ncsc-sim",
    storageBucket: "ncsc-sim.appspot.com",
    messagingSenderId: "828612939825",
    appId: "1:828612939825:web:7dab080445c4eab5ab0510"
  };
  

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export {firebaseApp, db, auth}